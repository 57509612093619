import React, { useEffect, useState, Fragment } from "react";
import { Button, Drawer, Layout, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { Grid } from "antd";
import "./AppHeader.css";
import logo from "../../../assets/images/Service-Global-Light.svg";
import logoDark from "../../../assets/images/Service-Global-Dark.svg";
import HeaderMenu from "./headerMenu/index";
import { MenuOutlined, SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import HeaderAvatar from "./headerAvatar";
import { connect } from "react-redux";
import ProfileProgressCardPage from "../../shared/profile-progress-card/ProfileProgressCard";
import { useStytch } from "@stytch/react";
import { logoutfn } from "../../../state/actions/auth";




const { Header } = Layout;
const { useBreakpoint } = Grid;
const logoStyle: React.CSSProperties = { cursor: 'pointer' }
const AppHeader: React.FC<any> = (props) => {
  const stytchClient = useStytch();
  const navigate = useNavigate();
  const { isLoggedIn, logoutState } = props;
  const { lg } = useBreakpoint();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true)
  };

  const onClose = () => {
    setVisible(false)
  };

  const navigateToHome = () => {
    navigate('/home', { replace: true });
  }

  const [isToggled, setToggled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 66) {
      setToggled(true)
    } else {
      setToggled(false)
    }
  }

  const onRedirect = () => {
    navigate('/profile', { replace: true });
    setVisible(false)
  }

  const onJobSearch = () => {
    navigate('/job-search', { replace: true });
    setVisible(false)
  }

  const onMyApp = () => {
    navigate('/application', { replace: true });
    setVisible(false)
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
  }, [])


  const onLogout = () => {
    stytchClient.session.revoke();
    logoutState();
    navigate('/login', { replace: true });
  }

  window.onpopstate = () => {
    navigate('/home', { replace: true });
  }

  return (
    <div key="appHeader">
      {isLoggedIn ?
        <Header className={isToggled ? 'header app-header static-header active' : 'pt-2 absolute header app-header static-header'}>
          {isToggled ? <img
            style={logoStyle}
            onClick={navigateToHome}
            className="app-logo"
            width={205}
            height={55}
            src={logoDark}
            alt={logo}
          /> : <img
            style={logoStyle}
            onClick={navigateToHome}
            className="app-logo"
            width={205}
            height={55}
            src={logo}
            alt={logo}
          />}
          <Fragment>
            <Space align="center" className="desktopMenu leftMenu">
              <HeaderMenu />
            </Space>
            <Space className="rightMenu mr-8">
              <HeaderAvatar />
            </Space>
          </Fragment>

          <div className="mobileMenu rightMenu">
            <Button className="barsBtn" onClick={showDrawer} icon={<MenuOutlined />} />
          </div>
          <Drawer placement="right" closable={true} onClose={onClose} open={visible} className="mobileMenuWrapper">

            <div className="mobileMenu">
              <Fragment>
                <ProfileProgressCardPage />

                <div className="flex justify-center mb-5 pb-5 B-Bottom">
                  <Button type="primary" onClick={onRedirect} >Complete Profile</Button>
                </div>

                <div className="white-rounded-bg ">
                  <span className="menuText" onClick={onJobSearch}><SearchOutlined className="mr-2" />Job Search</span><br />
                  <span className="menuText" onClick={onMyApp}><FileSearchOutlined className="mr-2" />My Application</span>
                </div>

                {/* <HeaderMenu /> */}

                <HeaderAvatar />
                <Button onClick={onLogout} className="mobileLogBtn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25.25" height="20.75" viewBox="0 0 29.25 24.75" className="mr-2">
                    <path id="Icon_ion-log-out-outline" data-name="Icon ion-log-out-outline" d="M21.375,23.625v2.813a2.812,2.812,0,0,1-2.812,2.813H7.313A2.812,2.812,0,0,1,4.5,26.438V9.563A2.813,2.813,0,0,1,7.313,6.75H18c1.553,0,3.375,1.259,3.375,2.813v2.813m4.5,11.25L31.5,18l-5.625-5.625M12.375,18h18" transform="translate(-3.375 -5.625)" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.25" />
                  </svg>
                  Logout</Button>
                {/* // onLogout()           */}

              </Fragment>
            </div>
          </Drawer>
        </Header> : <></>
      }
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return { isLoggedIn: state.reducer.auth.isLoggedIn };
};

function mapDispatchToProps(dispatch: any) {
  return { logoutState: () => dispatch(logoutfn()) };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
