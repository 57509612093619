import React, { Fragment } from 'react';
import { Avatar, Col, Progress, Row, Space, Tooltip, Typography } from 'antd';
import { connect } from "react-redux";
import { prepairPictureUrl } from '../../../utils/ActionUtility';
import { useNavigate } from 'react-router-dom';

const ProfileProgressCardPage: React.FC<any> = ({ user }) => {
    const navigate = useNavigate();
    const { email, full_name } = user || { phone: '', email: '', full_name: '' };
    const onRedirect = () => {
        navigate('/profile', { replace: true });
    }
    return (
        <Fragment>
            {email &&
              <Tooltip placement="top" title={'Click here to complete your profile.'}>
                <Row onClick={onRedirect}  className='pointer' >
                    <Col span={24} className='flex justify-end profileWrapper' style={{height: '10em'}}>
                        <Space size={30} >
                            <Avatar src={prepairPictureUrl(user?.picture)} size={65} style={{ border: "2px solid #ffff" }} />
                            <span>
                                <Typography.Title className="mt-1 mb-0 text-white" level={4}>{full_name}</Typography.Title>
                                <Typography.Text className="mt-2 text-white userTitle"> {user?.profile?.profile_title} </Typography.Text>
                            </span>
                            <div className='progressRound'>
                                {/* <Progress type="circle" percent={user?.profile?.completeness_percentage} width={45} strokeColor='#47b749' /> */}
                                
                                <Progress type="circle" percent={(user?.profile?.completeness_percentage > 100 ? 100 : user?.profile?.completeness_percentage)} width={80} strokeColor='#0AA500' format={() =>((user?.profile?.completeness_percentage > 100 ? 100 : user?.profile?.completeness_percentage) || 0) +'%'}/>
                            </div>
                        </Space>
                    </Col>
                    {/* {user?.profile?.completeness_percentage < 99 &&
                        <Col span={24} style={{textAlign: 'right'}}>
                            <Typography.Text>Complete your profile and start looking for jobs.</Typography.Text>
                        </Col>
                    } */}
                </Row>
                </Tooltip>
            }
        </Fragment>
    )
};
const mapStateToProps = (state: any) => {
    return { user: state.reducer.auth.user };
};

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProgressCardPage);